import {
    ChangeDetectionStrategy,
    Component,
    input,
    InputSignal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { fadeDisplayInOutAnimation } from '../../../domain/animations';
import { AsideCompanyDataComponent } from '../../shared/aside-company-data/aside-company-data.component';
import { AsideFooterComponent } from '../../shared/aside-footer/aside-footer.component';

@Component({
    selector: 'app-calculations-aside',
    standalone: true,
    imports: [AsideCompanyDataComponent, AsideFooterComponent, TranslateModule],
    templateUrl: './calculations-aside.component.html',
    styleUrl: './calculations-aside.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fadeDisplayInOutAnimation,
})
export class CalculationsAsideComponent {
    public calculationStep: InputSignal<number> = input(1);
}
