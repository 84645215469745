import {
    ChangeDetectionStrategy,
    Component,
    input,
    InputSignal,
} from '@angular/core';

@Component({
    selector: 'app-box',
    standalone: true,
    imports: [],
    templateUrl: './box.component.html',
    styleUrl: './box.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent {
    public displayFooter: InputSignal<boolean> = input(true);
}
