import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { FormAsideComponent } from '../../components/form/form-aside/form-aside.component';
import {
    Step1Component,
    Step1FormValues,
} from '../../components/form/step-1/step-1.component';
import {
    Step2Component,
    Step2FormValues,
} from '../../components/form/step-2/step-2.component';
import { fadeDisplayInOutAnimation } from '../../domain/animations';
import { CompanyResponse } from '../../domain/api-result';
import { LocalStorageCalculation } from '../../domain/local-storage';
import { LanguageService } from '../../services/language.service';

export interface FormValues extends Step1FormValues, Step2FormValues {}

@Component({
    selector: 'app-form',
    standalone: true,
    imports: [Step1Component, Step2Component, FormAsideComponent],
    templateUrl: './form.component.html',
    styleUrl: './form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fadeDisplayInOutAnimation,
})
export class FormComponent implements OnDestroy {
    private mainFormValues: FormValues = {
        address: '',
        bill: 6.93,
        email: '',
        equipment: 0,
        firstName: '',
        lastName: '',
        latitude: 10,
        longitude: 11,
    };

    protected step: WritableSignal<1 | 2> = signal(1);

    private company: CompanyResponse | undefined;

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly router: Router,
        private readonly languageService: LanguageService,
        readonly activatedRoute: ActivatedRoute,
    ) {
        this.subscription.add(
            activatedRoute.data.subscribe((data: Data): void => {
                this.company = data['company'];

                if (undefined === this.company) {
                    this.router.navigate(['']).then((): void => {});
                    return;
                }

                this.languageService.setLanguageOptions(this.company);

                const previousCalculation: LocalStorageCalculation = JSON.parse(
                    localStorage.getItem('previousCalculation') ?? '{}',
                );

                if (previousCalculation.company !== this.company.sunna_slug) {
                    return;
                }

                this.router
                    .navigate([this.company.sunna_slug, 'return'])
                    .then((): void => {});
            }),
        );
    }

    protected step1Submit(formValues: Step1FormValues): void {
        this.mainFormValues.address = formValues.address;
        this.mainFormValues.latitude = formValues.latitude;
        this.mainFormValues.longitude = formValues.longitude;
        this.mainFormValues.bill = formValues.bill;
        this.mainFormValues.equipment = formValues.equipment;

        this.step.set(2);
    }

    protected async step2Submit(formValues: Step2FormValues): Promise<void> {
        this.mainFormValues.firstName = formValues.firstName;
        this.mainFormValues.lastName = formValues.lastName;
        this.mainFormValues.email = formValues.email;

        await this.router
            .navigate([this.company?.sunna_slug, 'calculating'], {
                queryParams: {
                    address: this.mainFormValues.address,
                    latitude: this.mainFormValues.latitude,
                    longitude: this.mainFormValues.longitude,
                    bill: this.mainFormValues.bill,
                    equipment: this.mainFormValues.equipment,
                    firstName: this.mainFormValues.firstName,
                    lastName: this.mainFormValues.lastName,
                    email: this.mainFormValues.email,
                },
            })
            .then((): void => {});
    }

    protected step2Back(): void {
        this.step.set(1);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
