import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RedirectCommand,
    ResolveFn,
} from '@angular/router';
import * as Sentry from '@sentry/angular';
import { firstValueFrom } from 'rxjs';

import { AnalyzeResponse } from '../domain/api-result';
import { SunnaApiService } from '../services/sunna-api.service';

export const analyzeResolver: ResolveFn<
    AnalyzeResponse | undefined | string
> = async (
    route: ActivatedRouteSnapshot,
): Promise<AnalyzeResponse | undefined | string | RedirectCommand> => {
    const sunnaApiService: SunnaApiService = inject(SunnaApiService);

    try {
        sunnaApiService.prepareParameters({
            address: route.queryParams['address'],
            bill: route.queryParams['bill'],
            latitude: route.queryParams['latitude'],
            longitude: route.queryParams['longitude'],
            email: route.queryParams['email'],
            equipment: route.queryParams['equipment'],
            firstName: route.queryParams['firstName'],
            lastName: route.queryParams['lastName'],
        });

        return await firstValueFrom(
            sunnaApiService.analyze(route.paramMap.get('companySlug')!),
        );
    } catch (error) {
        Sentry.captureException(error);

        if (undefined !== (error as HttpErrorResponse).error?.calculationId) {
            return (error as HttpErrorResponse).error.calculationId;
        }

        return undefined;
    }
};
