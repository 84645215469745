import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { ErrorService } from './services/error.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, TranslateModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    constructor(protected readonly errorService: ErrorService) {}

    public ngOnInit(): void {
        const preconnect: HTMLLinkElement = document.createElement('link');
        preconnect.rel = 'preconnect';
        preconnect.href = environment.API_URL;
        document.head.appendChild(preconnect);
    }
}
