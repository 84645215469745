import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RedirectCommand,
    ResolveFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as Sentry from '@sentry/angular';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../environments/environment';
import { CompanyResponse } from '../domain/api-result';
import { ConfigService } from '../services/config.service';
import { SunnaApiService } from '../services/sunna-api.service';

export const companyResolver: ResolveFn<CompanyResponse | undefined> = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Promise<CompanyResponse | RedirectCommand | undefined> => {
    const router: Router = inject(Router);
    const sunnaApiService: SunnaApiService = inject(SunnaApiService);
    const configService: ConfigService = inject(ConfigService);

    try {
        const company: CompanyResponse = await firstValueFrom(
            sunnaApiService.company(route.paramMap.get('companySlug')!),
        );

        configService.mainColor.set(
            company?.appearance_colors_main ?? '#EFDECE',
        );
        configService.buttonColor.set(
            company?.appearance_colors_button ?? '#403B46',
        );
        configService.backgroundOpacity.set(
            company?.appearance_opacity_background ?? 0.12,
        );
        configService.backgroundImage.set(
            company?.appearance_brand_logo ?? null,
        );

        return company;
    } catch (error) {
        Sentry.captureException(error);

        if (state.url === `/${environment.DEFAULT_COMPANY_SLUG}`) {
            return undefined;
        }

        return new RedirectCommand(router.parseUrl(''));
    }
};
