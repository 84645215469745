import { Component, input, InputSignal } from '@angular/core';

/**
 * Renders loading indicator (facebook style) over a given component
 * Control displaying indicator via [isLoading]
 * And use [showContent] to decide if content should be displayed while [isLoading] is set to "true"
 *
 * Example usage:
 * @example
 * <app-loading [isLoading]="false" [showContent]="true">
 *     <div>something</div>
 * </app-loading>
 */
@Component({
    selector: 'app-loading',
    standalone: true,
    imports: [],
    template:
        '<div [class.is-loading]="isLoading()" [class.inline]="inline()" [class.show-content]="showContent()"><ng-content /></div>',
    styleUrl: './loading.component.scss',
})
export class LoadingComponent {
    public inline: InputSignal<boolean> = input(false);
    public isLoading: InputSignal<boolean> = input(false);
    public showContent: InputSignal<boolean> = input(false);
}
