import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CompanyResponse } from '../domain/api-result';
import { languages } from '../domain/languages';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly languages: string[] = languages;

    constructor(private readonly translateService: TranslateService) {}

    public setLanguageOptions(company: CompanyResponse | undefined): void {
        let preferredLang: string = '';

        if (undefined !== company) {
            preferredLang = company.language_code;
        }

        this.translateService.setDefaultLang('en');
        this.translateService.addLangs(this.languages);

        if (this.languages.includes(preferredLang)) {
            this.translateService.use(preferredLang);
            return;
        }

        for (const curLang of navigator.languages) {
            if (this.languages.includes(curLang)) {
                this.translateService.use(curLang);
                return;
            }
        }
    }
}
