import { NgOptimizedImage } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
    InputSignal,
    OnDestroy,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subscription } from 'rxjs';

import { CompanyResponse } from '../../../domain/api-result';
import { ConfigService } from '../../../services/config.service';
import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'app-aside-company-data',
    standalone: true,
    imports: [NgOptimizedImage, LoadingComponent],
    templateUrl: './aside-company-data.component.html',
    styleUrl: './aside-company-data.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideCompanyDataComponent implements OnDestroy {
    public isLoading: InputSignal<boolean> = input(false);
    public isInSidebar: InputSignal<boolean> = input(false);

    private companyData: WritableSignal<CompanyResponse | undefined> =
        signal(undefined);

    private subscription: Subscription = new Subscription();

    protected companyName: Signal<string> = computed((): string => {
        return this.companyData()?.name ?? '';
    });

    constructor(
        protected readonly configService: ConfigService,
        readonly activatedRoute: ActivatedRoute,
    ) {
        this.subscription.add(
            this.activatedRoute.data.subscribe((data: Data): void => {
                this.companyData.set(data['company']);
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
