import {
    Component,
    input,
    InputSignal,
    output,
    OutputEmitterRef,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-modal',
    standalone: true,
    imports: [ReactiveFormsModule, TranslateModule],
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss',
})
export class ModalComponent {
    public isOpen: InputSignal<boolean> = input(false);

    public closeEvent: OutputEmitterRef<void> = output();

    protected close(): void {
        this.closeEvent.emit();
    }
}
