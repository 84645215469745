import {
    ChangeDetectionStrategy,
    Component,
    model,
    ModelSignal,
    OnDestroy,
    output,
    OutputEmitterRef,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-toggle',
    standalone: true,
    imports: [],
    templateUrl: './toggle.component.html',
    styleUrl: './toggle.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent implements OnDestroy {
    public active: ModelSignal<boolean> = model(false);

    protected isReadonly: WritableSignal<boolean> = signal(false);

    private subscription: Subscription = new Subscription();

    public toggleEvent: OutputEmitterRef<boolean> = output<boolean>();

    constructor(readonly activatedRoute: ActivatedRoute) {
        this.subscription.add(
            activatedRoute.data.subscribe((data: Data): void => {
                this.isReadonly.set(undefined !== data['calculation']);
            }),
        );
    }

    protected toggleActive(): void {
        if (this.isReadonly()) {
            return;
        }

        this.active.set(!this.active());

        this.toggleEvent.emit(this.active());
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
