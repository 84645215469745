@if ('cash' === activeTab()) {
    <div data-tab="cash" class="price-container" [class.align-right]="alignRight()">
        <app-loading [isLoading]="isLoading()" [showContent]="prices().totalPrice !== 0">
            <span class="price">{{ prices().totalPrice | currency:'':'' }} {{ company()?.currency_code ?? 'USD' }}</span>
        </app-loading>
        <app-loading [isLoading]="isLoading()" [showContent]="prices().totalPricePerWp !== 0">
            <span class="price-per">{{ prices().totalPricePerWp | currency:'':'' }} {{ company()?.currency_code ?? 'USD' }}/Wp</span>
        </app-loading>
        <span class="annotation">*{{ 'The price includes the subsidy' | translate }}</span>
    </div>
}

@if ('leasing' === activeTab()) {
    <div data-tab="leasing" class="price-container" [class.align-right]="alignRight()">
        <app-loading [isLoading]="isLoading()" [showContent]="prices().totalPrice !== 0">
            <span class="price">{{ leasingPrice | currency:'':'' }} {{ company()?.currency_code ?? 'USD' }}/{{ 'month' | translate }}</span>
        </app-loading>
        <app-loading [isLoading]="isLoading()" [showContent]="prices().totalPricePerWp !== 0">
            <span class="price-per">{{ company()?.financial_duration_months ?? 0 }} {{ 'month' | translate }} | RRSO {{ company()?.financial_rrso ?? 0 }} %</span>
        </app-loading>
        <span class="annotation">*{{ 'The price includes the subsidy' | translate }}</span>
    </div>
}

@if (!isReadonly()) {
    <div class="btn-container">
        <button class="btn btn-primary" (click)="openModal()">{{ callToActionButtonLabel() ?? ('Order the full offer' | translate) }}</button>
    </div>
}

<div class="disclaimer"
   [innerHTML]="callToActionDescription() ?? ('THE OFFER PRESENTED IS PRELIMINARY AND MAY BE CHANGED AFTER DETAILED ANALYSIS BY AN ADVISOR. ORDER THE FULL OFFER FOR THIS PURPOSE.' | translate)">
</div>
