import { DecimalPipe, NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    Component,
    effect,
    input,
    InputSignal,
    model,
    ModelSignal,
    OnDestroy,
    output,
    OutputEmitterRef,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    Select2Data,
    Select2Module,
    Select2Option,
    Select2UpdateEvent,
    Select2UpdateValue,
    Select2Value,
} from 'ng-select2-component';
import { Subscription } from 'rxjs';

import { CountInputComponent } from '../../../../components/results/count-input/count-input.component';
import { SliderComponent } from '../../../../components/results/slider/slider.component';
import { ToggleComponent } from '../../../../components/results/toggle/toggle.component';
import {
    ApiResultSolarPanelConfiguration,
    CompanyResponse,
    Module,
} from '../../../../domain/api-result';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-energy-storage',
    standalone: true,
    imports: [
        CountInputComponent,
        ToggleComponent,
        Select2Module,
        NgOptimizedImage,
        TranslateModule,
        SliderComponent,
        DecimalPipe,
    ],
    templateUrl: './energy-storage.component.html',
    styleUrl: './energy-storage.component.scss',
})
export class EnergyStorageComponent implements AfterViewInit, OnDestroy {
    public batteryCount: InputSignal<number> = input.required();
    public open: ModelSignal<boolean> = model(false);
    public companyResponse: InputSignal<CompanyResponse | undefined> = input<
        CompanyResponse | undefined
    >(undefined);
    public apiResultSolarPanelConfiguration: InputSignal<
        ApiResultSolarPanelConfiguration | undefined
    > = input<ApiResultSolarPanelConfiguration | undefined>(undefined);
    public batteryType: InputSignal<Select2Option | undefined> = input<
        Select2Option | undefined
    >(undefined);

    protected energyStorageSliderValue: number = 0;
    protected chargingCarSliderValue: number = 0;

    protected batteryTypeValue: Select2UpdateValue = false;
    protected batteryTypeLabel: string | undefined;
    protected batteryTypeOptions: Select2Data = [];

    public sliderValuesUpdateEvent: OutputEmitterRef<{
        energyStorage: number;
        chargingCar: number;
    }> = output<{ energyStorage: number; chargingCar: number }>();
    public batteryTypeUpdateEvent: OutputEmitterRef<string> = output<string>();
    public changeBatteryCountEvent: OutputEmitterRef<number> = output<number>();
    public toggleBatteryEvent: OutputEmitterRef<boolean> = output<boolean>();

    protected isReadonly: WritableSignal<boolean> = signal(false);

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly modalService: ModalService,
        readonly activatedRoute: ActivatedRoute,
    ) {
        effect((): void => {
            this.setSelectedBatteryType(this.batteryType());
        });

        this.subscription.add(
            activatedRoute.data.subscribe((data: Data): void => {
                this.isReadonly.set(undefined !== data['calculation']);

                const modules: Module[] | undefined = data['modules'];

                if (undefined === modules) {
                    this.modalService.setAddressErrorModalState(true);
                    return;
                }

                this.batteryTypeOptions = [];

                modules.forEach((module: Module): void => {
                    if ('battery' === module.category) {
                        this.batteryTypeOptions.push({
                            label: this.getModuleLabel(module),
                            data: module.name,
                            value: module.id,
                        });
                    }
                });
            }),
        );
    }

    public ngAfterViewInit(): void {
        if (
            0 < this.batteryTypeOptions.length &&
            undefined === this.batteryType()
        ) {
            const value: Select2Value = (
                this.batteryTypeOptions[0] as Select2Option
            ).value;

            this.batteryTypeUpdate({
                value: value,
            } as Select2UpdateEvent);
        }
    }

    private getModuleLabel(module: Module): string {
        return `${module.name} <span class="badge badge-success">+${module.price} ${this.companyResponse()?.currency_code ?? 'USD'} / 1Wp</span>`;
    }

    private setSelectedBatteryType(option: Select2Option | undefined): void {
        this.batteryTypeValue = option?.value ?? '';
        if (option?.data) {
            this.batteryTypeLabel = option?.data;
        }
    }

    private get yearlyEnergyDcKwh(): number {
        return this.apiResultSolarPanelConfiguration()?.yearlyEnergyDcKwh ?? 0;
    }

    protected get energyStorageConsumption(): number {
        return this.yearlyEnergyDcKwh * (this.energyStorageSliderValue / 100);
    }

    protected get recommendedEnergyStorageSize(): number {
        return this.energyStorageConsumption / 360;
    }

    protected get carChargingConsumption(): number {
        return this.yearlyEnergyDcKwh * (this.chargingCarSliderValue / 100);
    }

    protected get solarEnergyCarRange(): number {
        return this.carChargingConsumption / 0.18;
    }

    protected batteryTypeUpdate(event: Select2UpdateEvent): void {
        if ('string' !== typeof event.value) {
            return;
        }

        this.batteryTypeUpdateEvent.emit(event.value);
    }

    protected changeBatteryCount(count: number): void {
        this.changeBatteryCountEvent.emit(count);
    }

    protected toggleSection(active: boolean): void {
        this.open.set(active);

        this.toggleBatteryEvent.emit(active);
    }

    protected updateEnergyStorageSlider(value: number): void {
        this.energyStorageSliderValue = value;

        this.chargingCarSliderValue = Math.min(
            this.chargingCarSliderValue,
            100 - value,
        );

        this.sliderValuesUpdateEvent.emit({
            chargingCar: this.chargingCarSliderValue,
            energyStorage: this.energyStorageSliderValue,
        });
    }

    protected updateChargingCarSlider(value: number): void {
        this.chargingCarSliderValue = value;

        this.energyStorageSliderValue = Math.min(
            this.energyStorageSliderValue,
            100 - value,
        );

        this.sliderValuesUpdateEvent.emit({
            chargingCar: this.chargingCarSliderValue,
            energyStorage: this.energyStorageSliderValue,
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
