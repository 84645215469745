<div class="box">
    <div class="box-title">
        <ng-content select="[title]" />
    </div>

    <div class="box-content" [class.without-footer]="!displayFooter()">
        <ng-content select="[content]" />
    </div>

    @if (displayFooter()) {
        <div class="box-footer">
            <ng-content select="[footer]" />
        </div>
    }
</div>
