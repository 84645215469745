import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
    InputSignal,
    Signal,
} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    standalone: true,
    imports: [],
    templateUrl: './progress-bar.component.html',
    styleUrl: './progress-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
    public value: InputSignal<number | string> = input.required();
    public start: InputSignal<number> = input(50);
    public end: InputSignal<number> = input(200);
    public unit: InputSignal<string> = input('%');

    protected minValue: Signal<number> = computed((): number => {
        const value: number | string = this.value();

        let start: number = this.start();

        if ('number' === typeof value && value < start) {
            start = Math.round(value) - 50;

            if (0 > start) {
                start = 0;
            }
        }

        return start;
    });

    protected maxValue: Signal<number> = computed((): number => {
        const value: number | string = this.value();

        let end: number = this.end();

        if ('number' === typeof value && value > end) {
            end = Math.round(value) + 50;
        }

        return end;
    });

    protected roundedValue: Signal<number | string> = computed(
        (): number | string => {
            const value: number | string = this.value();

            if ('number' === typeof value) {
                return Math.round(value);
            }

            return value;
        },
    );

    protected width: Signal<string> = computed((): string => {
        const value: number | string = this.value();
        const start: number = this.minValue();
        const end: number = this.maxValue();

        if ('string' === typeof value) {
            return '0px';
        }

        const calc: number = Math.round(
            (100 / (end - start)) * (value - start),
        );

        return `${calc}%`;
    });
}
