<div class="dialog-backdrop" [class.is-open]="isOpen()">
    <dialog>
        <div class="close-container">
            <button (click)="close()">
                <em class="icon icon-close"></em>
            </button>
        </div>
        <div class="dialog-container">
            <ng-content />
        </div>
    </dialog>
</div>
