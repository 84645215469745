import {
    ChangeDetectionStrategy,
    Component,
    computed,
    OnDestroy,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import {
    combineLatest,
    interval,
    map,
    mergeMap,
    Observable,
    Subscriber,
    Subscription,
    takeWhile,
} from 'rxjs';

import { CalculationsAsideComponent } from '../../components/calculations/calculations-aside/calculations-aside.component';
import { AddressErrorModalComponent } from '../../components/shared/address-error-modal/address-error-modal.component';
import { fadeInOutAnimation } from '../../domain/animations';
import {
    AnalyzeResponse,
    CompanyResponse,
    JobStatusResponse,
} from '../../domain/api-result';
import { ColorComponents, ConfigService } from '../../services/config.service';
import { LanguageService } from '../../services/language.service';
import { SunnaApiService } from '../../services/sunna-api.service';
import { FormValues } from '../form/form.component';
import { ModalService } from '../results/services/modal.service';

export interface QueryValues {
    address: string | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
    bill: number | undefined;
    equipment: number | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
}

@Component({
    selector: 'app-calculations',
    standalone: true,
    imports: [CalculationsAsideComponent, AddressErrorModalComponent],
    templateUrl: './calculations.component.html',
    styleUrl: './calculations.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fadeInOutAnimation,
})
export class CalculationsComponent implements OnDestroy {
    protected formValues: FormValues = {
        address: '',
        bill: 0,
        email: '',
        equipment: 0,
        firstName: '',
        lastName: '',
        latitude: 0,
        longitude: 0,
    };
    protected calculationStep: WritableSignal<number> = signal(1);
    protected intervalTime: number = 1000;
    protected jobId: string | undefined;

    private calculationSteps: string[] = [
        'scanning detecting edges',
        'checking sunlight',
        'detecting obstacles',
        'accounting for the distance from the edge',
        'fitting panels',
        'calculation of energy yields',
        'generating visualisation',
    ];
    private firstSurpassIndex: number | undefined;
    private calculationId: string | undefined;
    private sunnaSlug: string | undefined;

    protected darkerMainColor: Signal<string> = computed((): string => {
        const rgb: ColorComponents = this.configService.hex2rgb(
            this.mainColor(),
        );

        rgb.r = rgb.r - 20;
        rgb.g = rgb.g - 20;
        rgb.b = rgb.b - 20;

        if (rgb.r < 0) {
            rgb.r = 0;
        }

        if (rgb.g < 0) {
            rgb.g = 0;
        }

        if (rgb.b < 0) {
            rgb.b = 0;
        }

        return this.configService.rgb2hex(rgb);
    });

    protected mainColor: Signal<string> = computed((): string => {
        return this.configService.mainColor();
    });

    protected lighter1MainColor: Signal<string> = computed((): string => {
        const rgb: ColorComponents = this.configService.hex2rgb(
            this.mainColor(),
        );

        rgb.alpha = 0.9;

        return this.configService.rgb2hex(this.changeAlphaToColor(rgb));
    });

    protected lighter2MainColor: Signal<string> = computed((): string => {
        const rgb: ColorComponents = this.configService.hex2rgb(
            this.mainColor(),
        );

        rgb.alpha = 0.1;

        return this.configService.rgb2hex(this.changeAlphaToColor(rgb));
    });

    protected displayStep1: Signal<boolean> = computed((): boolean => {
        return 1 === this.calculationStep();
    });

    protected displayStep2: Signal<boolean> = computed((): boolean => {
        return 2 === this.calculationStep();
    });

    protected displayStep3: Signal<boolean> = computed((): boolean => {
        return (
            3 === this.calculationStep() ||
            4 === this.calculationStep() ||
            5 === this.calculationStep() ||
            6 === this.calculationStep() ||
            7 === this.calculationStep()
        );
    });

    protected displayStep4: Signal<boolean> = computed((): boolean => {
        return (
            4 === this.calculationStep() ||
            5 === this.calculationStep() ||
            6 === this.calculationStep() ||
            7 === this.calculationStep()
        );
    });

    protected displayStep5: Signal<boolean> = computed((): boolean => {
        return (
            5 === this.calculationStep() ||
            6 === this.calculationStep() ||
            7 === this.calculationStep()
        );
    });

    protected displayStep6: Signal<boolean> = computed((): boolean => {
        return 6 === this.calculationStep();
    });

    protected displayStep7: Signal<boolean> = computed((): boolean => {
        return 7 === this.calculationStep();
    });

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly sunnaApiService: SunnaApiService,
        private readonly modalService: ModalService,
        private readonly router: Router,
        private readonly languageService: LanguageService,
        private readonly configService: ConfigService,
        readonly activatedRoute: ActivatedRoute,
    ) {
        this.subscription.add(
            combineLatest([
                activatedRoute.data,
                activatedRoute.queryParams,
            ]).subscribe(
                ([data, queryParams]: [
                    data: Data,
                    queryParams: Params,
                ]): void => {
                    const company: CompanyResponse | undefined =
                        data['company'];

                    if (undefined === company) {
                        this.modalService.setAddressErrorModalState(true);
                        return;
                    }

                    this.init(
                        company,
                        data['analyze'],
                        queryParams as QueryValues,
                    );
                },
            ),
        );
    }

    private init(
        company: CompanyResponse,
        analyze: AnalyzeResponse | undefined,
        queryParams: QueryValues,
    ): void {
        this.languageService.setLanguageOptions(company);

        this.sunnaSlug = company.sunna_slug;

        if (
            undefined === queryParams.address ||
            undefined === queryParams.bill ||
            undefined === queryParams.email ||
            undefined === queryParams.equipment ||
            undefined === queryParams.firstName ||
            undefined === queryParams.lastName ||
            undefined === queryParams.latitude ||
            undefined === queryParams.longitude
        ) {
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        this.formValues = {
            address: queryParams.address,
            bill: queryParams.bill,
            email: queryParams.email,
            equipment: queryParams.equipment,
            firstName: queryParams.firstName,
            lastName: queryParams.lastName,
            latitude: queryParams.latitude,
            longitude: queryParams.longitude,
        };

        Sentry.metrics.increment('sunna_init', 1, {
            tags: { company: company?.sunna_slug },
        });

        if (undefined === analyze) {
            Sentry.metrics.increment('sunna_failed', 1, {
                tags: { company: company?.sunna_slug },
            });

            this.modalService.setAddressErrorModalState(true);
            return;
        }

        this.jobId = analyze.jobId;
        this.firstSurpassIndex = analyze.firstSurpassIndex;
        this.calculationId = analyze.calculationId;
        this.enableCheck();
    }

    private changeAlphaToColor(rgb: ColorComponents): ColorComponents {
        const bgRgb: ColorComponents = this.configService.hex2rgb(
            this.configService.backgroundColor(),
        );

        // combine main color with background color
        const combinedAlpha: number = (1 - rgb.alpha) * bgRgb.alpha + rgb.alpha;

        rgb.r =
            ((1 - rgb.alpha) * bgRgb.alpha * bgRgb.r + rgb.alpha * rgb.r) /
            combinedAlpha;
        rgb.g =
            ((1 - rgb.alpha) * bgRgb.alpha * bgRgb.g + rgb.alpha * rgb.g) /
            combinedAlpha;
        rgb.b =
            ((1 - rgb.alpha) * bgRgb.alpha * bgRgb.r + rgb.alpha * rgb.b) /
            combinedAlpha;
        rgb.alpha = combinedAlpha;

        // change rgba color to rgb (simplified background alpha to #FFFFFF for easier computation)
        const alpha: number = 1 - rgb.alpha;
        rgb.r = Math.round((rgb.alpha * (rgb.r / 255) + alpha) * 255);
        rgb.g = Math.round((rgb.alpha * (rgb.g / 255) + alpha) * 255);
        rgb.b = Math.round((rgb.alpha * (rgb.b / 255) + alpha) * 255);
        rgb.alpha = 1;

        return rgb;
    }

    /**
     * creates interval that every tick requests checkJobStatus
     */
    protected createInterval(): Observable<string> {
        if (undefined === this.jobId) {
            return new Observable<string>(
                (subscriber: Subscriber<string>): void => {
                    subscriber.error(new Error('Missing jobId'));
                },
            );
        }

        const jobId: string = this.jobId;

        return interval(this.intervalTime).pipe(
            mergeMap(() =>
                this.sunnaApiService
                    .checkJobStatus(jobId)
                    .pipe(map((res: JobStatusResponse): string => res.status)),
            ),
        );
    }

    private goToResults(jobId: string): void {
        this.router
            .navigate([this.sunnaSlug, 'results'], {
                queryParams: {
                    jobId: jobId,
                    firstSurpassIndex: this.firstSurpassIndex ?? 0,
                    calculationId: this.calculationId ?? '',
                    address: this.formValues.address,
                    latitude: this.formValues.latitude,
                    longitude: this.formValues.longitude,
                    bill: this.formValues.bill,
                    equipment: this.formValues.equipment,
                    firstName: this.formValues.firstName,
                    lastName: this.formValues.lastName,
                    email: this.formValues.email,
                },
            })
            .then((): void => {});
    }

    /**
     * subscribes to an interval when jobId is defined
     * then checks status from response
     * if status is different from "done"
     *      checks calculationSteps array for step index and updates animation
     * if status is "done"
     *      emits calculation ended Event and unsubscribes (`takeWhile`)
     */
    protected enableCheck(): void {
        if (undefined === this.jobId) {
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        const jobId: string = this.jobId;

        this.subscription.add(
            this.createInterval()
                .pipe(
                    takeWhile((status: string): boolean => {
                        return 'done' !== status;
                    }, true),
                )
                .subscribe({
                    next: (status: string): void => {
                        Sentry.metrics.increment('sunna_steps', 1, {
                            tags: { status: status },
                        });

                        if ('done' === status) {
                            this.goToResults(jobId);
                            return;
                        }

                        const index: number = this.calculationSteps.findIndex(
                            (stepStatus: string): boolean =>
                                stepStatus === status,
                        );

                        if (-1 !== index) {
                            this.calculationStep.set(index + 1);
                        } else {
                            this.calculationStep.set(1);
                        }
                    },
                    error: (): void => {
                        this.modalService.setAddressErrorModalState(true);
                    },
                }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
