import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RedirectCommand,
    ResolveFn,
    Router,
} from '@angular/router';
import * as Sentry from '@sentry/angular';
import { firstValueFrom } from 'rxjs';

import { Module, ModulesResponse } from '../domain/api-result';
import { SunnaApiService } from '../services/sunna-api.service';

const fetchModules = async (
    sunnaApiService: SunnaApiService,
    sunnaSlug: string,
    modules: Module[],
    page: number = 1,
): Promise<Module[]> => {
    const modulesResponse: ModulesResponse = await firstValueFrom(
        sunnaApiService.modules(sunnaSlug, page),
    );

    if (null !== modulesResponse.next) {
        modules = await fetchModules(
            sunnaApiService,
            sunnaSlug,
            modules,
            page + 1,
        );
    }

    modulesResponse.results.forEach((module: Module): void => {
        modules.push(module);
    });

    return modules;
};

export const modulesResolver: ResolveFn<Module[]> = async (
    route: ActivatedRouteSnapshot,
): Promise<RedirectCommand | Module[]> => {
    const router: Router = inject(Router);
    const sunnaApiService: SunnaApiService = inject(SunnaApiService);
    const sunnaSlug: string = route.paramMap.get('companySlug') ?? '';

    try {
        return await fetchModules(sunnaApiService, sunnaSlug, []);
    } catch (error) {
        Sentry.captureException(error);

        return new RedirectCommand(router.parseUrl(''));
    }
};
