import {
    Component,
    computed,
    ElementRef,
    input,
    InputSignal,
    OnInit,
    output,
    OutputEmitterRef,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';

@Component({
    selector: 'app-slider',
    standalone: true,
    imports: [],
    templateUrl: './slider.component.html',
    styleUrl: './slider.component.scss',
})
export class SliderComponent implements OnInit {
    public value: InputSignal<number> = input(0);
    public recommended: InputSignal<string> = input('');

    @ViewChild('toast', { static: true })
    protected toastElement!: ElementRef<HTMLElement>;

    @ViewChild('input', { static: true })
    protected inputElement!: ElementRef<HTMLInputElement>;

    protected show: WritableSignal<boolean> = signal(false);

    protected toastLeft: Signal<string> = computed((): string => {
        return `calc(${this.value()}% * 0.957)`;
    });

    public updateValue: OutputEmitterRef<number> = output<number>();

    public ngOnInit(): void {
        this.inputElement.nativeElement.addEventListener(
            'mouseenter',
            (): void => {
                this.show.set(true);
            },
        );
        this.inputElement.nativeElement.addEventListener(
            'mouseleave',
            (): void => {
                this.show.set(false);
            },
        );
    }

    protected onInput(event: Event): void {
        this.updateValue.emit(Number((event.target as HTMLInputElement).value));
    }
}
