import {
    Component,
    OnDestroy,
    OnInit,
    output,
    OutputEmitterRef,
} from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SafeParseReturnType } from 'zod';

import {
    LocalStorageCalculation,
    LocalStorageCalculationSchema,
} from '../../../domain/local-storage';
import { AsideCompanyDataComponent } from '../../shared/aside-company-data/aside-company-data.component';
import { AsideFooterComponent } from '../../shared/aside-footer/aside-footer.component';
import { LoadingComponent } from '../../shared/loading/loading.component';

@Component({
    selector: 'app-return-aside',
    standalone: true,
    imports: [
        AsideCompanyDataComponent,
        AsideFooterComponent,
        TranslateModule,
        LoadingComponent,
    ],
    templateUrl: './return-aside.component.html',
    styleUrl: './return-aside.component.scss',
})
export class ReturnAsideComponent implements OnInit, OnDestroy {
    protected address: string = '';
    protected power: number = 0;
    protected loaded: boolean = false;

    private companySlug: string | undefined = undefined;
    private subscription: Subscription = new Subscription();

    public returnToCalculation: OutputEmitterRef<void> = output<void>();
    public startNewCalculation: OutputEmitterRef<void> = output<void>();

    constructor(
        private readonly router: Router,
        readonly activatedRoute: ActivatedRoute,
    ) {
        this.subscription.add(
            activatedRoute.data.subscribe((data: Data): void => {
                this.companySlug = data['companySlug'];
            }),
        );
    }

    async ngOnInit(): Promise<void> {
        const jsonPreviousCalculation: string | null = localStorage.getItem(
            'previousCalculation',
        );

        if (null === jsonPreviousCalculation) {
            await this.router.navigate([this.companySlug]);
            return;
        }

        const localStorageCalculation: LocalStorageCalculation = JSON.parse(
            jsonPreviousCalculation,
        );

        const parse: SafeParseReturnType<unknown, unknown> =
            LocalStorageCalculationSchema.safeParse(localStorageCalculation);

        if (!parse.success) {
            await this.router.navigate([this.companySlug]);
            return;
        }

        this.address = localStorageCalculation.address;
        this.power = localStorageCalculation.power;
        this.loaded = true;
    }

    public onReturnToCalculation(): void {
        this.returnToCalculation.emit();
    }

    public onStartNewCalculation(): void {
        this.startNewCalculation.emit();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
