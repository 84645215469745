<div class="inputs">
    <div class="form-group">
        <label for="solar-panel">{{ 'Solar Panel' | translate }}</label>
        @if (isReadonly()) {
            <ng-container>{{ solarPanelLabel }}</ng-container>
        } @else {
            <select2
                id="solar-panel"
                required
                [value]="solarPanelValue"
                [data]="solarPanelOptions"
                (update)="solarPanelUpdate($event)"
            />
        }
    </div>
    <div class="form-group">
        <label for="inverter">{{ 'Inverter' | translate }}</label>
        @if (isReadonly()) {
            <ng-container>{{ inverterLabel }}</ng-container>
        } @else {
            <select2
                id="inverter"
                required
                [value]="inverterValue"
                [data]="inverterOptions"
                (update)="inverterUpdate($event)"
            />
        }
    </div>
</div>
