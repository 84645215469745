import {
    ChangeDetectionStrategy,
    Component,
    output,
    OutputEmitterRef,
    signal,
    WritableSignal,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { LoadingComponent } from '../../shared/loading/loading.component';

export interface Step2FormValues {
    firstName: string;
    lastName: string;
    email: string;
}

@Component({
    selector: 'app-step-2',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        LoadingComponent,
    ],
    templateUrl: './step-2.component.html',
    styleUrl: './step-2.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step2Component {
    protected isSubmitted: WritableSignal<boolean> = signal(false);

    protected step2Form: FormGroup = new FormGroup({
        firstName: new FormControl(null, Validators.required),
        lastName: new FormControl(null, Validators.required),
        email: new FormControl(null, [Validators.required, Validators.email]),
        agreement: new FormControl(false, [
            Validators.required,
            Validators.requiredTrue,
        ]),
    });

    public submitEvent: OutputEmitterRef<Step2FormValues> =
        output<Step2FormValues>();
    public backEvent: OutputEmitterRef<void> = output();

    protected get firstName(): AbstractControl<string | undefined> | null {
        return this.step2Form.get('firstName');
    }

    protected get lastName(): AbstractControl<string | undefined> | null {
        return this.step2Form.get('lastName');
    }

    protected get email(): AbstractControl<string | undefined> | null {
        return this.step2Form.get('email');
    }

    protected get agreement(): AbstractControl<boolean> | null {
        return this.step2Form.get('agreement');
    }

    protected back(): void {
        this.backEvent.emit();
    }

    protected formSubmit(): void {
        this.step2Form.markAsTouched();
        this.firstName?.markAsTouched();
        this.lastName?.markAsTouched();
        this.email?.markAsTouched();
        this.agreement?.markAsTouched();

        if (this.step2Form.invalid) {
            return;
        }

        this.isSubmitted.set(true);
        this.submitEvent.emit(this.step2Form.value);
    }
}
