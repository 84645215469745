import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export type FinancingType = 'cash' | 'leasing';

@Injectable({
    providedIn: 'root',
})
export class ActiveTabService {
    private activeTabSubject: Subject<FinancingType> =
        new Subject<FinancingType>();
    public activeTab: Observable<FinancingType> =
        this.activeTabSubject.asObservable();

    public setActiveTab(activeTab: FinancingType): void {
        this.activeTabSubject.next(activeTab);
    }
}
