import {
    animate,
    AnimationTriggerMetadata,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const fadeInOutAnimation: AnimationTriggerMetadata[] = [
    trigger('fade', [
        state(
            'in',
            style({
                opacity: 1,
            }),
        ),
        state(
            'out',
            style({
                opacity: 0,
            }),
        ),
        transition('in => out', [animate('200ms')]),
        transition('out => in', [animate('200ms 200ms')]),
    ]),
];

export const fadeDisplayInOutAnimation: AnimationTriggerMetadata[] = [
    trigger('fade', [
        state(
            'in',
            style({
                opacity: 1,
                display: 'block',
                'pointer-events': 'all',
            }),
        ),
        state(
            'out',
            style({
                opacity: 0,
                display: 'none',
                'pointer-events': 'none',
            }),
        ),
        transition('in => out', [animate('200ms')]),
        transition('out => in', [animate('200ms 200ms')]),
    ]),
];
