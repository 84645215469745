<form [formGroup]="phoneForm" (ngSubmit)="formSubmit()">
    <div class="form-group" [class.have-errors]="phone?.invalid && !phone?.untouched">
        <label for="email">{{ 'Phone' | translate }}</label>
        <input type="tel" formControlName="phone" id="email">
        @if (phone?.invalid && !phone?.untouched) {
            <div class="form-errors">
                @if (phone?.errors?.['required']) {
                    <div>{{ 'Phone is required.' | translate }}</div>
                }
            </div>
        }
    </div>
    <div class="form-group checkbox-form-group" [class.have-errors]="agreement?.invalid && !agreement?.untouched">
        <label>
            <input type="checkbox" formControlName="agreement">
            <span>{{ 'I consent to the processing of personal data and sending the offer.' | translate }}</span>
        </label>
        @if (agreement?.invalid && !agreement?.untouched) {
            <div class="form-errors">
                @if (agreement?.errors?.['required']) {
                    <div>{{ 'Agreement is required.' | translate }}</div>
                }
            </div>
        }
    </div>
    <div class="buttons">
        <button class="btn btn-primary btn-with-icon" type="submit">{{ submitText() }} <em class="icon icon-chevron-right-full"></em></button>
    </div>
</form>
