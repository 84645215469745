<app-modal [isOpen]="isOpen()" (closeEvent)="close()">
    <p class="dialog-title">{{ "Hi" | translate }},</p>
    <p>{{ 'provide your phone number and our advisor will contact you soon' | translate }}</p>

    <app-phone-form
        [submitText]="'I am ordering the full offer' | translate"
        (formSubmitEvent)="formSubmit($event)"
    />
</app-modal>

