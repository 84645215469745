import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    output,
    OutputEmitterRef,
    signal,
    WritableSignal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
    ModalData,
    ModalService,
} from '../../../page/results/services/modal.service';
import { ModalComponent } from '../../shared/modal/modal.component';
import { PhoneFormComponent } from '../../shared/phone-form/phone-form.component';

@Component({
    selector: 'app-phone-modal',
    standalone: true,
    imports: [ModalComponent, PhoneFormComponent, TranslateModule],
    templateUrl: './phone-modal.component.html',
    styleUrl: './phone-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneModalComponent implements OnDestroy {
    protected isOpen: WritableSignal<boolean> = signal(false);

    private subscription: Subscription = new Subscription();

    public submitEvent: OutputEmitterRef<string> = output<string>();

    constructor(private readonly modalService: ModalService) {
        this.subscription.add(
            this.modalService.phoneModal.subscribe((value: ModalData): void => {
                this.isOpen.set(value.open);
            }),
        );
    }

    protected close(): void {
        this.modalService.setPhoneModalState(false);
    }

    protected formSubmit(phone: string): void {
        this.close();

        this.submitEvent.emit(phone);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
