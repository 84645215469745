<div class="aside-company-data" [class.in-sidebar]="isInSidebar()">
    <app-loading [isLoading]="isLoading()" [showContent]="true">
        @if (configService.backgroundImage(); as companyLogo) {
            <img [ngSrc]="companyLogo" priority width="54" height="54" [alt]="companyName()">
        } @else {
            <img ngSrc="/assets/company.webp" priority width="54" height="54" [alt]="companyName()">
        }
    </app-loading>

    <app-loading [isLoading]="isLoading()" [showContent]="true">
        <p>{{ companyName() }}</p>
    </app-loading>
</div>
