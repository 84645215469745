export const languages: string[] = [
    'en', // English
    'pl', // Polish
    'es', // Spanish
    'pt', // Portuguese
    'sv', // Swedish
    'uk', // Ukrainian
    'nb', // Norwegian
    'nl', // Dutch
    'de', // German
    'bg-bg', // Bulgarian
    'sk', // Slovak
    'lv', // Latvian
    'ca', // Catalan
    'fr', // French
    'hr', // Croatian
    'el', // Greek
    'tr', // Turkish
    'cs', // Czech
    'da', // Danish
    'et', // Estonian
    'fi', // Finnish
    'hu', // Hungarian
    'it', // Italian
    'lt', // Lithuanian
    'ro', // Romanian
];
