import { z } from 'zod';

export interface LocalStorageCalculation {
    company: string;
    power: number;
    address: string;
    calculationId: string;
}

export const LocalStorageCalculationSchema: z.ZodSchema = z.object({
    company: z.string(),
    power: z.number(),
    address: z.string(),
    calculationId: z.string(),
});
