import { Component, OnDestroy, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SafeParseReturnType } from 'zod';

import { ReturnAsideComponent } from '../../components/return/return-aside/return-aside.component';
import { CompanyResponse } from '../../domain/api-result';
import {
    LocalStorageCalculation,
    LocalStorageCalculationSchema,
} from '../../domain/local-storage';

@Component({
    selector: 'app-return',
    standalone: true,
    imports: [ReturnAsideComponent],
    templateUrl: './return.component.html',
    styleUrl: './return.component.scss',
})
export class ReturnComponent implements OnDestroy {
    private company: WritableSignal<CompanyResponse | undefined> =
        signal(undefined);

    private subscription: Subscription = new Subscription();
    private calculationId: string | undefined;

    constructor(
        private readonly router: Router,
        readonly activatedRoute: ActivatedRoute,
    ) {
        this.subscription.add(
            activatedRoute.data.subscribe((data: Data): void => {
                this.company.set(data['company']);

                if (undefined === data['company']) {
                    this.router.navigate(['']).then((): void => {});
                    return;
                }
            }),
        );

        const jsonPreviousCalculation: string | null = localStorage.getItem(
            'previousCalculation',
        );

        if (null === jsonPreviousCalculation) {
            this.onStartNewCalculation();
            return;
        }

        const localStorageCalculation: LocalStorageCalculation | undefined =
            JSON.parse(jsonPreviousCalculation);

        const parse: SafeParseReturnType<unknown, unknown> =
            LocalStorageCalculationSchema.safeParse(localStorageCalculation);

        if (!parse.success) {
            this.onStartNewCalculation();
        }

        this.calculationId = localStorageCalculation?.calculationId;
    }

    public onReturnToCalculation(): void {
        if (undefined === this.company()) {
            this.router.navigate(['']).then((): void => {});
            return;
        }

        this.router
            .navigate([
                this.company()?.sunna_slug,
                'calculation',
                this.calculationId,
            ])
            .then((): void => {});
    }

    public onStartNewCalculation(): void {
        if (undefined === this.company()) {
            this.router.navigate(['']).then((): void => {});
            return;
        }

        localStorage.removeItem('previousCalculation');

        this.router.navigate([this.company()?.sunna_slug]).then((): void => {});
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
