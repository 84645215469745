import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RedirectCommand,
    ResolveFn,
    Router,
} from '@angular/router';
import * as Sentry from '@sentry/angular';
import { firstValueFrom } from 'rxjs';

import { GetCalculationResponse } from '../domain/api-result';
import { SunnaApiService } from '../services/sunna-api.service';

export const calculationResolver: ResolveFn<GetCalculationResponse> = async (
    route: ActivatedRouteSnapshot,
): Promise<GetCalculationResponse | RedirectCommand> => {
    const router: Router = inject(Router);
    const sunnaApiService: SunnaApiService = inject(SunnaApiService);

    try {
        return await firstValueFrom(
            sunnaApiService.calculation(route.paramMap.get('calculationId')!),
        );
    } catch (error) {
        Sentry.captureException(error);

        return new RedirectCommand(router.parseUrl(''));
    }
};
