import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface ModalData {
    open: boolean;
    data: unknown;
}

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private phoneModal$: Subject<ModalData> = new Subject<ModalData>();
    private calculationShareModal$: Subject<ModalData> =
        new Subject<ModalData>();
    private addressErrorModal$: Subject<ModalData> = new Subject<ModalData>();

    phoneModal: Observable<ModalData> = this.phoneModal$.asObservable();
    calculationShareModal: Observable<ModalData> =
        this.calculationShareModal$.asObservable();
    addressErrorModal: Observable<ModalData> =
        this.addressErrorModal$.asObservable();

    private openModal(
        subject: Subject<ModalData>,
        open: boolean,
        data: unknown,
    ): void {
        subject.next({
            open,
            data,
        });
    }

    setPhoneModalState(open: boolean, data: unknown = {}): void {
        this.openModal(this.phoneModal$, open, data);
    }

    setCalculationShareModalState(open: boolean, data: unknown = {}): void {
        this.openModal(this.calculationShareModal$, open, data);
    }

    setAddressErrorModalState(open: boolean, data: unknown = {}): void {
        this.openModal(this.addressErrorModal$, open, data);
    }
}
