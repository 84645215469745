import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
    InputSignal,
    OnDestroy,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Select2Option } from 'ng-select2-component/lib/select2-interfaces';
import { Subscription } from 'rxjs';

import {
    ApiResultSolarPanelConfiguration,
    CompanyResponse,
} from '../../../domain/api-result';
import { ModalService } from '../../../page/results/services/modal.service';
import { AsideCompanyDataComponent } from '../../shared/aside-company-data/aside-company-data.component';
import { AsideFooterComponent } from '../../shared/aside-footer/aside-footer.component';
import { LoadingComponent } from '../../shared/loading/loading.component';
import { BoxComponent } from '../box/box.component';
import { PriceComponent } from '../price/price.component';
import { TabListComponent } from '../tab-list/tab-list.component';

interface ViewModel {
    systemPower: number;
    panelCount: number;
    panelLabel: string;
    inverterLabel: string;
}

@Component({
    selector: 'app-results-aside',
    standalone: true,
    imports: [
        AsideFooterComponent,
        AsideCompanyDataComponent,
        BoxComponent,
        TabListComponent,
        TranslateModule,
        LoadingComponent,
        PriceComponent,
    ],
    templateUrl: './results-aside.component.html',
    styleUrl: './results-aside.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsAsideComponent implements OnDestroy {
    public isLoading: InputSignal<boolean> = input(false);
    public apiResultSolarPanelConfiguration: InputSignal<
        ApiResultSolarPanelConfiguration | undefined
    > = input<ApiResultSolarPanelConfiguration | undefined>(undefined);
    public selectedSolarPanel: InputSignal<Select2Option | undefined> = input<
        Select2Option | undefined
    >(undefined);
    public selectedInverter: InputSignal<Select2Option | undefined> = input<
        Select2Option | undefined
    >(undefined);

    protected company: WritableSignal<CompanyResponse | undefined> =
        signal(undefined);

    private subscription: Subscription = new Subscription();

    protected viewModel: Signal<ViewModel> = computed(
        (): ViewModel => ({
            systemPower:
                this.apiResultSolarPanelConfiguration()?.systemPowerDcKwh ?? 0,
            panelCount:
                this.apiResultSolarPanelConfiguration()?.totalPanelCount ?? 0,
            panelLabel: this.selectedSolarPanel()?.label ?? '',
            inverterLabel: this.selectedInverter()?.label ?? '',
        }),
    );

    constructor(
        readonly modalService: ModalService,
        readonly activatedRoute: ActivatedRoute,
    ) {
        this.subscription.add(
            activatedRoute.data.subscribe((data: Data): void => {
                if (undefined === data['company']) {
                    this.modalService.setAddressErrorModalState(true);
                    return;
                }

                this.company.set(data['company']);
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
