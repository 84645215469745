import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
    InputSignal,
    output,
    OutputEmitterRef,
    Signal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CountInputComponent } from '../../../../components/results/count-input/count-input.component';
import { ImagePanelsComponent } from '../../../../components/results/image-panels/image-panels.component';
import { ProgressBarComponent } from '../../../../components/results/progress-bar/progress-bar.component';
import { LoadingComponent } from '../../../../components/shared/loading/loading.component';
import {
    ApiResultSolarPanelConfiguration,
    CompanyResponse,
    PanelCoordinates,
} from '../../../../domain/api-result';

@Component({
    selector: 'app-concept',
    standalone: true,
    imports: [
        CountInputComponent,
        ProgressBarComponent,
        TranslateModule,
        ImagePanelsComponent,
        CurrencyPipe,
        DecimalPipe,
        LoadingComponent,
    ],
    templateUrl: './concept.component.html',
    styleUrl: './concept.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConceptComponent {
    public image: InputSignal<string | undefined> = input<string | undefined>(
        undefined,
    );
    public isLoading: InputSignal<boolean> = input(false);
    public apiResultSolarPanelConfiguration: InputSignal<
        ApiResultSolarPanelConfiguration | undefined
    > = input();
    public companyResponse: InputSignal<CompanyResponse | undefined> = input();
    public allowIncrement: InputSignal<boolean> = input(true);
    public allowDecrement: InputSignal<boolean> = input(true);

    private readonly DEFAULT_ENERGY_PRICE_KWH: number = 0.693;

    public changePanelsCountEvent: OutputEmitterRef<number> = output<number>();

    protected panelsCount: Signal<number> = computed((): number => {
        return this.apiResultSolarPanelConfiguration()?.totalPanelCount ?? 0;
    });

    protected energyOffsetPercent: Signal<number> = computed((): number => {
        return (
            this.apiResultSolarPanelConfiguration()?.energyOffsetPercent ?? 0
        );
    });

    protected yearlyEnergyDcKwh: Signal<number> = computed((): number => {
        return this.apiResultSolarPanelConfiguration()?.yearlyEnergyDcKwh ?? 0;
    });

    protected systemPower: Signal<number> = computed((): number => {
        return this.apiResultSolarPanelConfiguration()?.systemPowerDcKwh ?? 0;
    });

    protected consumption: Signal<string> = computed((): string => {
        const energyPrice: number =
            this.companyResponse()?.energy_price_for_kwh ??
            this.DEFAULT_ENERGY_PRICE_KWH;

        return ((this.oldBillMonthly() / energyPrice) * 12).toFixed(2);
    });

    protected hasConsumption: Signal<boolean> = computed(
        (): boolean => parseFloat(this.consumption()) > 0,
    );

    protected oldBillMonthly: Signal<number> = computed((): number => {
        return this.apiResultSolarPanelConfiguration()?.oldBillMonthly ?? 0;
    });

    protected newBillMonthly: Signal<number> = computed((): number => {
        const newBill: number | undefined =
            this.apiResultSolarPanelConfiguration()?.newBillMonthly;

        if (undefined === newBill || 0 > newBill) {
            return 0;
        }

        return newBill;
    });

    protected currency: Signal<string> = computed((): string => {
        return this.companyResponse()?.currency_code ?? 'USD';
    });

    protected panelCoordinatePoints: Signal<PanelCoordinates[]> = computed(
        (): PanelCoordinates[] => {
            return (
                this.apiResultSolarPanelConfiguration()
                    ?.panelCoordinatePoints ?? []
            );
        },
    );

    protected changePanelsCount(count: number): void {
        this.changePanelsCountEvent.emit(count);
    }
}
