<div class="aside-content">
    <app-aside-company-data />

    <app-box>
        <ng-container title>
            {{ 'Your summary' | translate }}
        </ng-container>

        <ng-container content>
            <app-loading [isLoading]="isLoading()" [showContent]="true">
                <ul class="check-list">
                    <li>{{ viewModel().systemPower }} kWp</li>
                    <li>{{ 'Construction' | translate }}</li>
                    <li>{{ 'The price includes tax' | translate }}</li>
                    <li>{{ viewModel().panelCount }} {{ 'panels' | translate }} {{ viewModel().panelLabel }}</li>
                    <li>{{ 'Full installation' | translate }}</li>
                    <li>{{ 'Inverter' | translate }} {{ viewModel().inverterLabel }}</li>
                    <li>{{ 'Warranty' | translate }}</li>
                </ul>
            </app-loading>
        </ng-container>

        <ng-container footer>
            <div class="footer-content">
                @if (company()?.financial_enabled ?? false) {
                    <app-tab-list />
                }

                <app-price
                    [company]="company()"
                    [isLoading]="isLoading()"
                />
            </div>
        </ng-container>
    </app-box>
</div>

<app-aside-footer />
