import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import {
    AnalyzeResponse,
    CalculationRequest,
    CalculationResponse,
    CompanyResponse,
    GetCalculationResponse,
    JobStatusResponse,
    ModulesResponse,
    ResultResponse,
} from '../domain/api-result';
import { FormValues } from '../page/form/form.component';
import { ModalService } from '../page/results/services/modal.service';

@Injectable({
    providedIn: 'root',
})
export class SunnaApiService {
    private name$: string = '';
    private surname$: string = '';
    private email$: string = '';
    private latitude$: number = -90;
    private longitude$: number = -180;
    private billMonthly$: number = 1;
    private address$: string = '';

    constructor(
        private readonly httpClient: HttpClient,
        private readonly modalService: ModalService,
    ) {}

    public prepareParameters(formValues: FormValues | undefined): void {
        if (undefined === formValues) {
            this.modalService.setAddressErrorModalState(true);

            throw new Error('formValues is undefined');
        }

        this.name$ = formValues.firstName;
        this.surname$ = formValues.lastName;
        this.email$ = formValues.email;
        this.latitude$ = formValues.latitude;
        this.longitude$ = formValues.longitude;
        this.billMonthly$ = formValues.bill;
        this.address$ = formValues.address;
    }

    public get name(): string {
        return this.name$;
    }

    public get surname(): string {
        return this.surname$;
    }

    public get email(): string {
        return this.email$;
    }

    public get latitude(): number {
        return this.latitude$;
    }

    public get longitude(): number {
        return this.longitude$;
    }

    public get billMonthly(): number {
        return this.billMonthly$;
    }

    public get address(): string {
        return this.address$;
    }

    public analyze(sunnaSlug: string): Observable<AnalyzeResponse> {
        return this.httpClient.post<AnalyzeResponse>(
            `${environment.API_URL}api/m/roof-md-0014/analyze/`,
            {
                name: this.name,
                surname: this.surname,
                email: this.email,
                latitude: this.latitude,
                longitude: this.longitude,
                bill_monthly: this.billMonthly,
                address: this.address,
            },
            {
                headers: {
                    'X-Company': sunnaSlug,
                },
            },
        );
    }

    public checkJobStatus(jobId: string): Observable<JobStatusResponse> {
        return this.httpClient.get<JobStatusResponse>(
            `${environment.API_URL}api/m/roof-md-0014/job/${jobId}/status/`,
        );
    }

    public result(jobId: string, index: number): Observable<ResultResponse> {
        return this.httpClient.get<ResultResponse>(
            `${environment.API_URL}api/m/roof-md-0014/job/${jobId}/result/?idx=${index}`,
        );
    }

    public saveCalculation(
        calculationId: string,
        calculationData: CalculationRequest,
    ): Observable<CalculationResponse> {
        return this.httpClient.patch<CalculationResponse>(
            `${environment.API_URL}api/calculation/${calculationId}/`,
            calculationData,
        );
    }

    public calculation(
        calculationId: string,
    ): Observable<GetCalculationResponse> {
        return this.httpClient.get<GetCalculationResponse>(
            `${environment.API_URL}api/calculation/${calculationId}/`,
        );
    }

    public company(companySlug: string): Observable<CompanyResponse> {
        return this.httpClient.get<CompanyResponse>(
            `${environment.API_URL}api/company/${companySlug}/`,
        );
    }

    public modules(
        sunnaSlug: string,
        pageNumber: number = 1,
    ): Observable<ModulesResponse> {
        return this.httpClient.get<ModulesResponse>(
            `${environment.API_URL}api/modules/?page=${pageNumber}`,
            {
                headers: {
                    'X-Company': sunnaSlug,
                },
            },
        );
    }
}
