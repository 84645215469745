export interface Environment {
    PRODUCTION: boolean;
    API_URL: string;
    SENTRY_DSN: string;
    RELEASE: string;
    DEFAULT_COMPANY_SLUG: string;
}

export const baseEnvironment: Environment = {
    PRODUCTION: false,
    RELEASE: 'release-version',
    API_URL: 'https://sunna-api-production.easysolar-app.com/',
    SENTRY_DSN: '',
    DEFAULT_COMPANY_SLUG: 'example-company-1',
};
