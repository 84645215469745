import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    effect,
    input,
    InputSignal,
    OnDestroy,
    output,
    OutputEmitterRef,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    Select2Data,
    Select2Module,
    Select2Option,
    Select2UpdateEvent,
    Select2UpdateValue,
    Select2Value,
} from 'ng-select2-component';
import { Subscription } from 'rxjs';

import { CompanyResponse, Module } from '../../../../domain/api-result';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-components-select',
    standalone: true,
    imports: [Select2Module, TranslateModule],
    templateUrl: './components-select.component.html',
    styleUrl: './components-select.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentsSelectComponent implements AfterViewInit, OnDestroy {
    public companyResponse: InputSignal<CompanyResponse | undefined> = input<
        CompanyResponse | undefined
    >(undefined);

    public solarPanel: InputSignal<Select2Option | undefined> = input<
        Select2Option | undefined
    >(undefined);
    public inverter: InputSignal<Select2Option | undefined> = input<
        Select2Option | undefined
    >(undefined);

    protected solarPanelValue: Select2UpdateValue = false;
    protected inverterValue: Select2UpdateValue = false;

    protected solarPanelLabel: string | undefined;
    protected inverterLabel: string | undefined;

    protected solarPanelOptions: Select2Data = [];
    protected inverterOptions: Select2Data = [];

    public solarPanelUpdateEvent: OutputEmitterRef<string> = output<string>();
    public inverterUpdateEvent: OutputEmitterRef<string> = output<string>();

    protected isReadonly: WritableSignal<boolean> = signal(false);

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly modalService: ModalService,
        readonly activatedRoute: ActivatedRoute,
    ) {
        effect((): void => {
            this.companyResponse();
            this.setSelectedSolarPanel(this.solarPanel());
        });

        effect((): void => {
            this.companyResponse();
            this.setSelectedInverter(this.inverter());
        });

        this.subscription.add(
            activatedRoute.data.subscribe((data: Data): void => {
                this.isReadonly.set(undefined !== data['calculation']);

                const modules: Module[] | undefined = data['modules'];

                if (undefined === modules) {
                    this.modalService.setAddressErrorModalState(true);
                    return;
                }

                this.modulesInit(modules);
            }),
        );
    }

    public ngAfterViewInit(): void {
        if (
            0 < this.solarPanelOptions.length &&
            undefined === this.solarPanel()
        ) {
            const value: Select2Value = (
                this.solarPanelOptions[0] as Select2Option
            ).value;

            this.solarPanelUpdate({
                value: value,
            } as Select2UpdateEvent);
        }

        if (0 < this.inverterOptions.length && undefined === this.inverter()) {
            const value: Select2Value = (
                this.inverterOptions[0] as Select2Option
            ).value;

            this.inverterUpdate({
                value: value,
            } as Select2UpdateEvent);
        }
    }

    private modulesInit(modules: Module[]): void {
        this.solarPanelOptions = [];
        this.inverterOptions = [];

        modules.forEach((module: Module): void => {
            if ('panel' === module.category) {
                this.solarPanelOptions.push({
                    label: this.getModuleLabel(module),
                    value: module.id,
                });
            } else if ('inverter' === module.category) {
                this.inverterOptions.push({
                    label: this.getModuleLabel(module),
                    value: module.id,
                });
            }
        });
    }

    private getModuleLabel(module: Module): string {
        return `${module.name} <span class="badge badge-success">+${module.price} ${this.companyResponse()?.currency_code ?? 'USD'} / 1Wp</span>`;
    }

    private setSelectedSolarPanel(option: Select2Option | undefined): void {
        this.solarPanelValue = option?.value ?? this.solarPanelValue ?? false;
        this.solarPanelLabel = option?.label ?? this.solarPanelLabel ?? '';
    }

    private setSelectedInverter(option: Select2Option | undefined): void {
        this.inverterValue = option?.value ?? this.inverterValue ?? false;
        this.inverterLabel = option?.label ?? this.inverterLabel ?? '';
    }

    protected solarPanelUpdate(event: Select2UpdateEvent): void {
        if ('string' !== typeof event.value) {
            return;
        }

        this.solarPanelUpdateEvent.emit(event.value);
    }

    protected inverterUpdate(event: Select2UpdateEvent): void {
        if ('string' !== typeof event.value) {
            return;
        }

        this.inverterUpdateEvent.emit(event.value);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
