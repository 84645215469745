import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { CompanyResponse } from '../../../domain/api-result';
import { AsideCompanyDataComponent } from '../../shared/aside-company-data/aside-company-data.component';
import { AsideFooterComponent } from '../../shared/aside-footer/aside-footer.component';

@Component({
    selector: 'app-form-aside',
    standalone: true,
    imports: [AsideCompanyDataComponent, AsideFooterComponent, TranslateModule],
    templateUrl: './form-aside.component.html',
    styleUrl: './form-aside.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAsideComponent implements OnDestroy {
    private subscription: Subscription = new Subscription();

    protected company: WritableSignal<CompanyResponse | undefined> =
        signal(undefined);

    constructor(readonly activatedRoute: ActivatedRoute) {
        this.subscription.add(
            activatedRoute.data.subscribe((data: Data): void => {
                this.company.set(data['company']);
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
