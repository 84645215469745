<div class="offer-content">
    @if (companyResponse()?.financial_enabled ?? false) {
        <app-tab-list/>
    }

    <app-loading [isLoading]="isLoading()" [showContent]="true">
        <app-inner-box id="your-system">
            <ng-container title>{{ 'Your system' | translate }}</ng-container>
            <ng-container sub-title>{{ prices().systemPrice | currency:'':'' }} <span class="smaller">{{ companyResponse()?.currency_code ?? 'USD' }}</span></ng-container>
            @if ('' !== (companyResponse()?.proposal_description ?? '').trim()) {
                <ng-container content>
                    <div [innerHTML]="companyResponse()?.proposal_description"></div>
                </ng-container>
            }
        </app-inner-box>
    </app-loading>
    <app-loading [isLoading]="isLoading()" [showContent]="true">
        <app-inner-box>
            <ng-container title>{{ 'Warranty' | translate }}</ng-container>
            <ng-container sub-title>{{ 'Included in the price' | translate }}</ng-container>
            @if ('' !== (companyResponse()?.warranty_description ?? '').trim()) {
                <ng-container content>
                    <div [innerHTML]="companyResponse()?.warranty_description"></div>
                </ng-container>
            }
        </app-inner-box>
    </app-loading>
    <app-loading [isLoading]="isLoading()" [showContent]="true">
        @if (companyResponse()?.dotations_enabled) {
            <app-inner-box id="grant">
                <ng-container title>{{ 'Grant' | translate }}</ng-container>
                <ng-container sub-title>{{ prices().grantPrice | currency:'':'' }} <span class="smaller">{{ companyResponse()?.currency_code ?? 'USD' }}</span></ng-container>

                @if ('' !== (companyResponse()?.dotations_description ?? '').trim()) {
                    <ng-container content>
                        <div [innerHTML]="companyResponse()?.dotations_description"></div>
                    </ng-container>
                }
            </app-inner-box>
        }
    </app-loading>
    <div class="offer-price">
        <app-price
            [alignRight]="true"
            [company]="companyResponse()"
            [isLoading]="isLoading()"
        />
    </div>
</div>
