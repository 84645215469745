import { CurrencyPipe } from '@angular/common';
import {
    Component,
    computed,
    input,
    InputSignal,
    OnDestroy,
    OnInit,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Data } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { CompanyResponse } from '../../../domain/api-result';
import {
    CalculatePricesService,
    CalculationResultData,
} from '../../../page/results/services/calculate-prices.service';
import { ModalService } from '../../../page/results/services/modal.service';
import {
    ActiveTabService,
    FinancingType,
} from '../../../services/active-tab.service';
import { LoadingComponent } from '../../shared/loading/loading.component';

@Component({
    selector: 'app-price',
    standalone: true,
    imports: [CurrencyPipe, LoadingComponent, TranslateModule],
    templateUrl: './price.component.html',
    styleUrl: './price.component.scss',
})
export class PriceComponent implements OnInit, OnDestroy {
    public alignRight: InputSignal<boolean> = input(false);
    public isLoading: InputSignal<boolean> = input(false);
    public company: InputSignal<CompanyResponse | undefined> = input<
        CompanyResponse | undefined
    >(undefined);

    protected prices: WritableSignal<CalculationResultData> = signal({
        basePrice: 0,
        batteryPrice: 0,
        grantPrice: 0,
        inverterPrice: 0,
        panelPrice: 0,
        systemPower: 0,
        systemPrice: 0,
        totalBatteryPrice: 0,
        totalPrice: 0,
        totalPricePerWp: 0,
    });

    protected activeTab: WritableSignal<FinancingType> = signal('cash');
    protected isReadonly: WritableSignal<boolean> = signal(false);

    protected callToActionButtonLabel: Signal<string | null> = computed(
        (): string | null => {
            const buttonLabel: string =
                this.company()?.call_to_action_button_label ?? '';

            if ('' === buttonLabel) {
                return null;
            }

            return buttonLabel;
        },
    );

    protected callToActionDescription: Signal<SafeHtml | null> = computed(
        (): SafeHtml | null => {
            const description: string =
                this.company()?.call_to_action_description ?? '';

            if ('' === description) {
                return null;
            }

            return this.domSanitizer.bypassSecurityTrustHtml(description);
        },
    );

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly modalService: ModalService,
        private readonly calculatePricesService: CalculatePricesService,
        private readonly activeTabService: ActiveTabService,
        private readonly domSanitizer: DomSanitizer,
        readonly activatedRoute: ActivatedRoute,
    ) {
        this.subscription.add(
            this.activeTabService.activeTab.subscribe(
                (activeTab: FinancingType): void => {
                    this.activeTab.set(activeTab);
                },
            ),
        );

        this.subscription.add(
            activatedRoute.data.subscribe((data: Data): void => {
                this.isReadonly.set(undefined !== data['calculation']);
            }),
        );
    }

    public async ngOnInit(): Promise<void> {
        this.subscription.add(
            this.calculatePricesService
                .calculationSub()
                .subscribe((prices: CalculationResultData): void => {
                    this.prices.set(prices);
                }),
        );

        this.calculatePricesService.forceRecalculate(undefined);
    }

    protected get leasingPrice(): number {
        if (
            null === this.company()?.financial_duration_months ||
            undefined === this.company()?.financial_duration_months ||
            null === this.company()?.financial_rrso ||
            undefined === this.company()?.financial_rrso
        ) {
            return 0;
        }

        const rrsoPercent: number = (this.company()?.financial_rrso ?? 0) / 100;

        let z: number = 0;

        for (
            let i: number = 1;
            i <= (this.company()?.financial_duration_months ?? 0);
            i++
        ) {
            z += 1 / Math.pow(1 + rrsoPercent / 12, i);
        }

        return this.prices().totalPrice / z;
    }

    protected openModal(): void {
        this.modalService.setPhoneModalState(true);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
