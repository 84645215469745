import {
    ChangeDetectionStrategy,
    Component,
    input,
    InputSignal,
    model,
    ModelSignal,
    OnDestroy,
    output,
    OutputEmitterRef,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-count-input',
    standalone: true,
    imports: [],
    templateUrl: './count-input.component.html',
    styleUrl: './count-input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountInputComponent implements OnDestroy {
    public value: ModelSignal<number> = model(1);

    public stopChange: InputSignal<boolean> = input(false);
    public allowIncrement: InputSignal<boolean> = input(true);
    public allowDecrement: InputSignal<boolean> = input(true);

    protected isReadonly: WritableSignal<boolean> = signal(false);

    private subscription: Subscription = new Subscription();

    public changeCount: OutputEmitterRef<number> = output<number>();

    constructor(readonly activatedRoute: ActivatedRoute) {
        this.subscription.add(
            activatedRoute.data.subscribe((data: Data): void => {
                this.isReadonly.set(undefined !== data['calculation']);
            }),
        );
    }

    protected increment(): void {
        if (this.isReadonly() || !this.allowIncrement()) {
            return;
        }

        this.propagate(this.value() + 1);
    }

    protected decrement(): void {
        if (this.isReadonly() || !this.allowDecrement()) {
            return;
        }

        if (1 === this.value()) {
            return;
        }

        this.propagate(this.value() - 1);
    }

    private propagate(value: number): void {
        if (!this.stopChange()) {
            this.value.set(value);
        }

        this.changeCount.emit(value);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
