<div class="headline">
    <div class="title">
        <ng-content select="[title]" />
    </div>
    <div class="sub-title">
        <ng-content select="[sub-title]" />
    </div>
</div>

<div class="content">
    <ng-content select="[content]" />
</div>
