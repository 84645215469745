<main>
    <app-results-aside
        [isLoading]="isLoading()"
        [apiResultSolarPanelConfiguration]="apiResultSolarPanelConfiguration"
        [selectedSolarPanel]="selectedSolarPanel()"
        [selectedInverter]="selectedInverter()"
    />
    <div class="content">
        <div class="title-container">
            <div>
                <p class="title">{{ 'Your Photovoltaic System' | translate }}</p>
                <p class="sub-title">
                    {{ 'Our recommendation for address is an powerkW system, which will cover your current energy bill and reduce your monthly costs by reduction currency.' | translate:{
                        address: apiResultSolarPanelConfiguration?.address ?? '',
                        power: (apiResultSolarPanelConfiguration?.systemPowerDcKwh ?? 0) | number: '1.2-2',
                        reduction: (oldBillMonthly - newBillMonthly) | currency:'':'',
                        currency: companyResponse?.currency_code ?? 'USD'
                } }}
                </p>
            </div>

            @if (calculationId) {
                <div class="with-button">
                    <button id="share-button" class="btn btn-hollow {{ calculationId }}" (click)="share()">
                        <span>{{ 'Share the offer' | translate }}</span>
                        <em class="icon icon-share"></em>
                    </button>
                </div>
            }

        </div>

        <app-box [displayFooter]="false">
            <ng-container title>
                {{ 'Your summary' | translate }}
            </ng-container>

            <ng-container content>
                <app-concept
                    [isLoading]="isLoading()"
                    [allowDecrement]="havePrevPage"
                    [allowIncrement]="haveNextPage"
                    [image]="image"
                    [apiResultSolarPanelConfiguration]="apiResultSolarPanelConfiguration"
                    [companyResponse]="companyResponse"
                    (changePanelsCountEvent)="changePanelsCount($event)"
                />
            </ng-container>
        </app-box>

        <app-box [displayFooter]="false">
            <ng-container title>
                {{ 'Component selection' | translate }}
            </ng-container>

            <ng-container content>
                <app-loading [isLoading]="isLoading()" [showContent]="true">
                    <app-components-select
                        [solarPanel]="selectedSolarPanel()"
                        [inverter]="selectedInverter()"
                        [companyResponse]="companyResponse"
                        (solarPanelUpdateEvent)="solarPanelUpdate($event)"
                        (inverterUpdateEvent)="inverterUpdate($event)"
                    />
                </app-loading>
            </ng-container>
        </app-box>

        <app-box [displayFooter]="false">
            <ng-container title>
                {{ 'Plan energy storage' | translate }}
            </ng-container>

            <ng-container content>
                <app-loading [isLoading]="isLoading()" [showContent]="true">
                    <app-energy-storage
                        [batteryCount]="batteryCount"
                        [open]="batteryEnabled"
                        [apiResultSolarPanelConfiguration]="apiResultSolarPanelConfiguration"
                        [batteryType]="selectedBatteryType()"
                        (sliderValuesUpdateEvent)="sliderValuesUpdate($event)"
                        (batteryTypeUpdateEvent)="batteryTypeUpdate($event)"
                        (changeBatteryCountEvent)="batteryCountUpdate($event)"
                        (toggleBatteryEvent)="toggleBatteryUpdate($event)"
                    />
                </app-loading>
            </ng-container>
        </app-box>

        <app-box [displayFooter]="false">
            <ng-container title>
                {{ 'Introductory offer' | translate }}
            </ng-container>

            <ng-container content>
                <app-offer
                    [isLoading]="isLoading()"
                    [companyResponse]="companyResponse"
                />
            </ng-container>
        </app-box>
    </div>
</main>

<app-address-error-modal [formValues]="formValues" [name]="formValues?.firstName ?? ''" />
<app-phone-modal (submitEvent)="submitModal($event)" />
<app-calculation-share-modal />
